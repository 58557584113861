:root {
  --orange: #DC780A;
  --orange-75: #D67C4F;
  --orange-50: #EDBB84;
  --orange-20: #F8E4CE;
  --orange-10: #FBEEE0;
  --dark-orange-darken: #943B0F;
  --dark-orange: #C85014;
  --dark-orange-75: #D67C4F;
  --dark-orange-50: #E3A789;
  --dark-orange-25: #F1D3C4;
  --red: #C82800;
  --red-75:#D65E40;
  --red-50:#E39380;
  --red-25:#F1C7BD;
  --dark-green-blue: #17333A;
  --dark-green-blue-75: #51666B;
  --dark-green-blue-50: #8B999C;
  --dark-green-blue-20: #C5CCCE;
  --green-blue: #36595A;
  --green-blue-75: #688283;
  --green-blue-50: #9AACAC;
  --green-blue-20: #CDD5D6;
  --green: #3C932D;
  --green-20: #D3DFDA;
  --white: #FCFCF8;
  --clear-white: #FFFFFF;
  --gray: #787878;
  --gray-75: #9A9A9A;
  --gray-50: #BBBBBB;
  --gray-25: #DDDDDD;
  --black: #000000;
  --black-75: #404040;
  --black-50: #808080;
  --black-25: #BFBFBF;
  --dark-purple: #351822;
  
  --gray-alpha: rgba(33, 37, 41, 0.6);
}
