:root {
    /* Spacing and layout */
    --spacing-base: 1rem;   /* 10px */
    --spacing-xs: 1.5rem;   /* 15px */
    --spacing-small: 2rem;  /* 20px */
    --spacing-small-medium: 3rem; /* 30px */
    --spacing-medium: 4rem; /* 40px */
    --spacing-large: 6rem;  /* 60px */
    --spacing-xl: 8rem;     /* 80px */
    --spacing-xxl: 12rem;   /* 120px */
    --spacing-centered: 0 auto;

    /* Content max widths */
    --max-width-content: 116rem;       /* 1160px */
    --max-width-content-wide: 128rem;  /* 1280px */
    --max-width-content-half: 55.5rem; /* 555px */
    --max-width-box: 64rem;            /* 640px */
    --max-width-box-wide: 82rem;       /* 820px */
    --max-full-width-text: 60rem;      /* 600px */

    /* Borders */
    --border-xs: 0.1rem;     /* 1px */
    --border-small: 0.2rem;  /* 2px */
    --border-medium: 0.3rem; /* 3px */
    --border-large: 0.4rem;  /* 4px */
    --border-xl: 0.7rem;     /* 7px */
    
    /* Border radius
       -----------------------------------------
       Mixin for rounding corners with half
       of the elements height can be found in
       /scss/mixins/_general-mixins.scss
    */
    --border-radius-xxs: 0.2rem;    /* 2px */
    --border-radius-xs: 0.6rem;     /* 6px */
    --border-radius-small: 0.8rem;  /* 8px */
    --border-radius-medium: 1.2rem; /* 12px */
    --border-radius-large: 2rem;    /* 20px */
    --border-radius-xl: 4rem;       /* 40px */
    --border-radius-round: 50%;
    
    /* Clickable elements, buttons and links */
    --click-surface-min-size: 4.4rem;
    --button-size: 5.3rem;
    --button-inner-shadow:  inset 0 0.3rem 0.4rem 0 rgba(0, 0, 0, 0.25);
    
    /* Form elements */
    --input-height: 4.7rem;
    --text-area-height: 13.2rem;

    /* Transitions */
    --transition-timing : 0.2s;
    --transition-base : all var(--transition-timing) ease-in-out;
    --transition-padding: padding var(--transition-timing) ease-in-out;
    --transition-left: left var(--transition-timing) ease-in-out;
    --transition-width: width var(--transition-timing) ease-in-out;
    
    /* Disturbance */
    --disturbance-height: 0px;
    
    /* Header */
    --header-logo-height-small: 2.8rem;      /* 28px */
    --header-logo-height-large: 3rem;        /* 30px */
    --header-toggle-button-width: 4rem;      /* 40px */
    --header-toggle-button-height: 4.2rem;   /* 42px */
    --header-padding-bottom-desktop: 2.4rem; /* 24px */
    --header-tool-menu-height: 3.6rem;       /* 36px */
    --header-padding-middle-desktop: 0.4rem; /* 4px */
    
    --header-height-mobile: calc(var(--disturbance-height) + var(--spacing-small) + var(--header-toggle-button-height) + var(--spacing-small));
    --header-height-desktop: calc(var(--disturbance-height) + var(--header-tool-menu-height) + var(--header-padding-middle-desktop) + var(--spacing-base) + var(--header-toggle-button-height) + var(--header-padding-bottom-desktop));
    
    /* Images */
    --fixed-image-height-xs: 24rem;
    --fixed-image-height-sm: 32rem;
    --fixed-image-height-md: 44rem;
    --fixed-image-height-lg: 52rem;
}
